import React from 'react';
import { AbsoluteContainer, RelativeContainer } from '@pitchero/react-ui';
import PropTypes from 'prop-types';

const SixteenByNineFrame = ({ children }) => {
  return (
    <RelativeContainer style={{ width: '100%', paddingTop: '56.25%' }}>
      <AbsoluteContainer top={0} bottom={0} left={0} right={0}>
        {children}
      </AbsoluteContainer>
    </RelativeContainer>
  );
};

SixteenByNineFrame.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default SixteenByNineFrame;
