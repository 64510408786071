import React from 'react';
import PropTypes from 'prop-types';
import ActivityStreamItem from '../item';

const TeamSelectionMessage = ({ message, ...otherProps }) => {
  return (
    <ActivityStreamItem
      imageSrc={message.image}
      linkProps={{
        route: 'team.match_centre.lineup',
        params: { teamId: message.team_id, fixtureId: message.fixture.fixture_id },
      }}
      messageType={message.type}
      published={message.published}
      title={message.title}
      {...otherProps}
    />
  );
};

TeamSelectionMessage.propTypes = {
  message: PropTypes.shape({
    fixture: PropTypes.shape({
      fixture_id: PropTypes.string.isRequired,
    }).isRequired,
    image: PropTypes.string.isRequired,
    published: PropTypes.string.isRequired,
    team_id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
};

export default TeamSelectionMessage;
